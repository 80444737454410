import { createApp } from 'vue';

import App from './App.vue';

import router from './router';
import {i18n} from './i18n';

const scss = () => import('./scss/custom.scss');
const bootstrap_collapse = () => import('bootstrap/js/dist/collapse');

const app = createApp(App);
app.use(router);
app.use(scss);
app.use(bootstrap_collapse);
app.use(i18n);

app.mount('#app');
