<template>
  <div>
    <!-- Pre navbar background -->
    <div v-if="isHome" class="image-container d-none d-md-block position-relative hidden-overflow">
      <div>
        <div class="text-center">
          <img height="200px" src="./assets/images/herberth-briefkopf.jpg" alt="Briefkopf" />
        </div>
        <!-- <img width="4096" height="302" src="./assets/images/navbar-logo.svg" alt="Landscape Header" /> -->
      </div>
    </div>
    <div v-else class="image-container d-none d-md-block position-relative hidden-overflow">
      <div>
        <div class="text-center">
          <img height="124px" src="./assets/images/herberth-briefkopf-short.jpg" alt="Briefkopf" />
        </div>
        <!-- <img width="4096" height="302" src="./assets/images/navbar-logo.svg" alt="Landscape Header" /> -->
      </div>
    </div>
    <!-- Navbar -->
    <nav id="pageNavbar" class="navbar navbar-expand-lg sticky-top bg-primary-light p-0 py-2 text-center custom-shadow-b">
      <div class="container-lg justify-content-end">
        <button id="btnNavbarToggler" class="navbar-toggler btn" type="button" data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
          aria-label="Toggle navigation">
          <img src="./assets/bootstrap_icons/list.svg" alt="Menu" width="32" height="32" />
        </button>
        <div class="collapse navbar-collapse text-end" id="navbarSupportedContent">
          <ul class="navbar-nav mx-auto mb-3 mb-lg-0 gap-2">
            <li class="nav-item">
              <router-link :to="'/' + $i18n.locale" exact-active-class="custom-active"
                class="btn w-100 fs-5 text-end text-secondary text-nowrap">{{ $t("Navigation.Home") }}</router-link>
            </li>
            <li class="nav-item">
              <router-link :to="'/' + $i18n.locale + '/beveragemarket'" exact-active-class="custom-active"
                class="btn w-100 fs-5 text-end text-secondary text-nowrap">{{ $t("Navigation.BeverageMarket")
                }}</router-link>
            </li>
            <li class="nav-item">
              <router-link :to="'/' + $i18n.locale + '/events'" exact-active-class="custom-active"
                class="btn w-100 fs-5 text-end text-secondary text-nowrap">{{ $t("Navigation.Events") }}</router-link>
            </li>
            <li class="nav-item">
              <router-link :to="'/' + $i18n.locale + '/delivery'" exact-active-class="custom-active"
                class="btn w-100 fs-5 text-end text-secondary text-nowrap">{{ $t("Navigation.Delivery") }}</router-link>
            </li>
            <li class="nav-item">
              <router-link :to="'/' + $i18n.locale + '/products'" exact-active-class="custom-active"
                class="btn w-100 fs-5 text-end text-secondary text-nowrap">{{ $t("Navigation.Products") }}</router-link>
            </li>
            <li class="nav-item">
              <router-link :to="'/' + $i18n.locale + '/gallery'" exact-active-class="custom-active"
                class="btn w-100 fs-5 text-end text-secondary text-nowrap">{{ $t("Navigation.Gallery") }}</router-link>
            </li>
            <li class="nav-item">
              <router-link :to="'/' + $i18n.locale + '/contact'" exact-active-class="custom-active"
                class="btn w-100 fs-5 text-end text-secondary text-nowrap">{{ $t("Navigation.Contact") }}</router-link>
            </li>
          </ul>
        </div>
      </div>
    </nav>

    <!-- Site content -->
    <router-view v-if="!isHome && !isDelivery" class="navbar-padding" />
    <router-view v-if="isHome || isDelivery" />

    <!-- Footer -->
    <footer class="custom-shadow-t footer-padding bg-dark text-white small">
      <div class="container">

        <!-- Links -->
        <div class="row">
          <div class="col-sm-4 mb-1">
            <!-- check for list-group coloring-->
            <ul class="list-group list-group-flush">
              <li class="list-group-item border-0 p-0 bg-transparent text-white fs-5">
                <b>{{ $t("Footer.Address.Title") }}</b>
                <br />
              </li>
              <li class="list-group-item border-0 p-0 bg-transparent text-white opacity-60">
                <p>
                  {{ $t("General.Address.Company") }}<br /><br />

                  {{ $t("General.Address.Street") }} {{ $t("General.Address.Location") }}<br />

                  {{ $t("General.PhoneNumbers.PhoneText") }} {{ $t("General.PhoneNumbers.Phone") }}<br />
                  {{ $t("General.PhoneNumbers.FaxText") }} {{ $t("General.PhoneNumbers.Fax") }}<br />
                  {{ $t("Footer.Address.Email") }}<a href="mailto:info@herberth.de" class="text-white">
                    info@herberth.de
                  </a><br />
                  {{ $t("Footer.Address.Internet") }}<a href="https://www.herberth.de" class="text-white">
                    www.herberth.de
                  </a><br />
                </p>
              </li>
            </ul>
          </div>
          <div class="col-sm-4 mb-1">
            <ul class="list-group list-group-flush">
              <li class="list-group-item border-0 p-0 bg-transparent text-white fs-5">
                <b>{{ $t("Footer.Links.Title") }}</b>
                <br />
              </li>
              <li class="list-group-item border-0 p-0 bg-transparent text-white">
                <p class="opacity-60 fs-6">
                  <router-link :to="'/' + $i18n.locale" class="text-white">{{ $t("Navigation.Home") }}</router-link>
                  <br />
                  <router-link :to="'/' + $i18n.locale + '/beveragemarket'" class="text-white">{{
                    $t("Navigation.BeverageMarket") }}</router-link>
                  <br />
                  <router-link :to="'/' + $i18n.locale + '/events'" class="text-white">{{ $t("Navigation.Events")
                  }}</router-link>
                  <br />
                  <router-link :to="'/' + $i18n.locale + '/delivery'" class="text-white">{{ $t("Navigation.Delivery")
                  }}</router-link>
                  <br />
                  <router-link :to="'/' + $i18n.locale + '/products'" class="text-white">{{ $t("Navigation.Products")
                  }}</router-link>
                  <br />
                  <router-link :to="'/' + $i18n.locale + '/gallery'" class="text-white">{{ $t("Navigation.Gallery")
                  }}</router-link>
                  <br />
                  <router-link :to="'/' + $i18n.locale + '/jobads'" class="text-white">{{ $t("Navigation.JobAds")
                  }}</router-link>
                  <br />
                  <router-link :to="'/' + $i18n.locale + '/contact'" class="text-white">{{ $t("Navigation.Contact")
                  }}</router-link>
                </p>
              </li>
            </ul>
          </div>
          <div class="col-sm-4 mb-1">
            <ul class="list-group list-group-flush">
              <li class="list-group-item border-0 p-0 bg-transparent text-white fs-5">
                <b>{{ $t("Footer.Legal.Title") }}</b>
                <br />
              </li>
              <li class="list-group-item border-0 p-0 bg-transparent">
                <p class="opacity-60 fs-6">
                  <router-link :to="'/' + $i18n.locale + '/termsandconditions'" class="text-white">{{
                    $t("Navigation.TermsAndConditions") }}</router-link>
                  <br />
                  <router-link :to="'/' + $i18n.locale + '/legalnotice'" class="text-white">{{
                    $t("Navigation.LegalNotice") }}</router-link>
                  <br />
                  <router-link :to="'/' + $i18n.locale + '/privacy'" class="text-white">{{ $t("Navigation.Privacy")
                  }}</router-link>
                </p>
              </li>
            </ul>
          </div>
        </div>

        <!-- Social Area -->
        <div class="row pt-4">
          <!-- Separator -->
          <div class="footer-separator"></div>
          <!-- Links -->
          <div class="text-center mt-5">
            <p class="fs-5">{{ $t("Footer.Social.Title") }}</p>
            <div class="row justify-content-center">
              <div class="col-auto footer-icon">
                <a href="https://www.instagram.com/kelterei_herberth/"><img src="./assets/bootstrap_icons/instagram.svg"
                    alt="Instagram" width="30" height="30" /></a>
              </div>
              <div class="col-auto footer-icon">
                <a href="https://www.facebook.com/profile.php?id=61552422597730"><img
                    src="./assets/bootstrap_icons/facebook.svg" alt="Facebook" width="30" height="30" /></a>
              </div>
            </div>
          </div>
        </div>

      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: "App",
  mounted() {
    var navLinks = document.querySelectorAll(".nav-item");
    navLinks.forEach((l) => {
      l.addEventListener("click", () => {
        if (window.innerWidth <= 992) {
          document.getElementById("btnNavbarToggler").click();
        }
      });
    });
  },
  computed: {
    isHome() {
      return this.$route.name === 'Home';
    },
    isDelivery() {
      return this.$route.name === 'Delivery';
    }
  },
  methods: {
  },
  setup() { },
  data() { },
};
</script>
<style scoped>
.fixed-sides {
  right: 0;
  left: 0;
}

.custom-active {
  text-decoration: underline;
  font-weight: bold;
}
</style>