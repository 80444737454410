import { createRouter, createWebHistory } from 'vue-router'

import { Trans } from '../js/translation'

const Home = () => import('../views/Home.vue')
const BeverageMarket = () => import('../views/BeverageMarket.vue')
const Events = () => import('../views/Events.vue')
const Delivery = () => import('../views/Delivery.vue')
const Products = () => import('../views/Products.vue')
const Gallery = () => import('../views/Gallery.vue')
const Contact = () => import('../views/Contact.vue')
const Privacy = () => import('../views/Privacy.vue')
const LegalNotice = () => import('../views/LegalNotice.vue')
const JobAds = () => import('../views/JobAds.vue')
const TermsAndConditions = () => import('../views/TermsAndConditions.vue')

const routes = [
  {
    path: "/",
    component: {
      template: "<router-view></router-view>"
    },
    redirect: { name: 'Home', params: { lang: Trans.defaultLanguage } }
  },
  {
    path: '/:lang',
    name: 'Lang',
    component: {
      template: "<router-view></router-view>"
    },
    beforeEnter: Trans.routeMiddleware,
    children: [
      {
        path: '',
        name: 'Home',
        component: Home
      },
      {
        path: 'beveragemarket',
        name: 'BeverageMarket',
        component: BeverageMarket
      },
      {
        path: 'events',
        name: 'Events',
        component: Events
      },
      {
        path: 'delivery',
        name: 'Delivery',
        component: Delivery
      },
      {
        path: 'products',
        name: 'Products',
        component: Products
      },
      {
        path: 'gallery',
        name: 'Gallery',
        component: Gallery
      },
      {
        path: 'jobads',
        name: 'JobAds',
        component: JobAds
      },
      {
        path: 'contact',
        name: 'Contact',
        component: Contact
      },
      {
        path: 'privacy',
        name: 'Privacy',
        component: Privacy
      },
      {
        path: 'legalnotice',
        name: 'LegalNotice',
        component: LegalNotice
      },
      {
        path: 'termsandconditions',
        name: 'TermsAndConditions',
        component: TermsAndConditions
      },
    ]
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  }
})

router.beforeEach((to, from, next) => {
  var btn = document.getElementById("btnDismissModal");
  if(btn){
    btn.click();
  }
  next();
});

export default router
